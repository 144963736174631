<template>
	<div class="delete">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、需先添加生效员工后，才能记录到该员工的删人记录。</span></p>
			<p><span class="black">2、系统将自动记录生效员工删除客户与被客户删除的操作记录。</span></p>
		</div>
		<el-tabs style="margin-top: 15px;" v-model="activeName" type="card">
			<el-tab-pane label="删除记录" name="record">
				<el-form :inline="true" :model="form" ref="formRef">
					<el-form-item label="成员" prop="b_name"><el-input size="small" v-model="form.b_name" placeholder="请输入成员名称"></el-input></el-form-item>
					<el-form-item label="客户" prop="c_name"><el-input size="small" v-model="form.c_name" placeholder="请输入客户名称"></el-input></el-form-item>
					<el-form-item>
						<el-button
							size="small"
							class="btn-blue"
							@click="
								() => {
									page = 1;
									getTableData();
								}
							"
						>
							查询
						</el-button>
						<el-button
							size="small"
							class="btn-blue"
							@click="
								() => {
									$refs.formRef.resetFields();
									page = 1;
									getTableData();
								}
							"
						>
							清空
						</el-button>
					</el-form-item>
				</el-form>
				<div class="table" style="margin-top: 15px;">
					<el-table
						v-loading="loading"
						ref="table"
						:data="tableData"
						style="width: 100%"
						:header-cell-style="{
							color: '#333',
							fontSize: '14px',
							backgroundColor: '#F6F7FB',
							fontWeight: 'normal'
						}"
					>
						<el-table-column label="企业成员">
							<template v-slot="scope">
								<div style="display: flex;align-items: center;">
									<img style="width: 35px;height: 35px;border-radius: 50%;" :src="scope.row.bavatar" />
									<span class="ellipsis" style="width: auto; display: inline-block;max-width: 100px;margin: 0 10px;">{{ scope.row.bname }}</span>
									<img v-show="scope.row.bgender == 1" src="../../assets/images/boy.png" width="16" alt="" />
									<img v-show="scope.row.bgender == 2" src="../../assets/images/girl.png" width="16" alt="" />
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="cname" label="删除客户">
							<template v-slot="scope">
								<div style="display: flex;align-items: center;">
									<img style="width: 35px;height: 35px;border-radius: 50%;" :src="scope.row.cavatar" />
									<span class="ellipsis" style="width: auto; display: inline-block;max-width: 100px;margin: 0 10px;">{{ scope.row.cname }}</span>
									<img v-show="scope.row.cgender == 1" src="../../assets/images/boy.png" width="16" alt="" />
									<img v-show="scope.row.cgender == 2" src="../../assets/images/girl.png" width="16" alt="" />
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="active_user" label="主动删除方"></el-table-column>
						<el-table-column prop="name">
							<template slot="header">
								标签
								<!-- 	<el-tooltip class="item" placement="top">
									<div slot="content" class="my-tip">标签说明</div>
									<i class="iconfont icon-question-circle"></i>
								</el-tooltip> -->
							</template>
							<template v-slot="scope">
								<el-tag style="margin: 0 10px 10px 0;" v-for="(item, index) in scope.row.tag_ids" :key="index">{{ item }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="create_at" label="删除时间"></el-table-column>
					</el-table>
				</div>
				<div class="pagination" v-if="total !== 0">
					<el-pagination
						@size-change="
							pageSize => {
								limit = pageSize;
								getTableData();
							}
						"
						@current-change="
							currentPage => {
								page = currentPage;
								getTableData();
							}
						"
						:current-page="page"
						:page-sizes="[10, 20, 50, 100]"
						:page-size="limit"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total"
					></el-pagination>
				</div>
			</el-tab-pane>
			<el-tab-pane label="生效员工" name="effective">
				<keep-alive><Effect v-if="activeName === 'effective'"></Effect></keep-alive>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import Effect from './effect.vue';
import { reqGetDelPersonList } from '@/api/index';
export default {
	components: {
		Effect
	},
	data() {
		return {
			activeName: 'record',
			showCourse: false,
			options: [],
			page: 1,
			limit: 10,
			total: 0,
			tableData: [],
			loading: false,
			form: {}
		};
	},
	created() {
		this.getTableData();
	},
	methods: {
		getTableData() {
			this.loading = true;
			reqGetDelPersonList({
				page: this.page,
				limit: this.limit,
				b_name: this.form.b_name,
				c_name: this.form.c_name
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				this.loading = false;
			});
		}
	}
};
</script>

<style lang="less" scoped>
.delete {
	.el-tab-pane {
		> div + div {
			margin-top: 15px;
		}
	}
	.forms {
		display: flex;
		align-items: center;
	}
}
</style>
