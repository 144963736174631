var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"effect"},[_c('div',{staticClass:"forms"},[_c('el-form',{ref:"formRef",attrs:{"inline":true,"model":_vm.form}},[_c('el-form-item',{attrs:{"prop":"is_open"}},[_c('el-select',{staticStyle:{"width":"200px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.form.is_open),callback:function ($$v) {_vm.$set(_vm.form, "is_open", $$v)},expression:"form.is_open"}},[_c('el-option',{attrs:{"label":"全部状态","value":""}}),_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})})],2)],1),_c('el-form-item',{attrs:{"prop":"keyword"}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"size":"small","placeholder":"请输入成员名称查询"},model:{value:(_vm.form.keyword),callback:function ($$v) {_vm.$set(_vm.form, "keyword", $$v)},expression:"form.keyword"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"btn-blue",attrs:{"size":"small"},on:{"click":function () {
							_vm.page = 1;
							_vm.getTableData();
						}}},[_vm._v(" 查找 ")]),_c('el-button',{staticClass:"btn-white",attrs:{"size":"small"},on:{"click":function () {
							_vm.$refs.formRef.resetFields();
							_vm.page = 1;
							_vm.getTableData();
						}}},[_vm._v(" 清空 ")])],1),_c('el-form-item',{staticStyle:{"float":"right"}},[_c('el-button',{staticClass:"btn-blue",attrs:{"size":"small"},on:{"click":_vm.handleAdd}},[_vm._v("添加生效员工")])],1)],1)],1),_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"header-cell-style":{
				color: '#333',
				fontSize: '14px',
				backgroundColor: '#F6F7FB',
				fontWeight: 'normal'
			}}},[_c('el-table-column',{attrs:{"label":"企业成员"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"35px","height":"35px","border-radius":"50%"},attrs:{"src":scope.row.avatar}}),_c('span',{staticClass:"ellipsis",staticStyle:{"width":"auto","display":"inline-block","max-width":"120px","margin":"0 10px"}},[_vm._v(_vm._s(scope.row.name))]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(scope.row.gender == 1),expression:"scope.row.gender == 1"}],attrs:{"src":require("../../assets/images/boy.png"),"width":"16","alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(scope.row.gender == 2),expression:"scope.row.gender == 2"}],attrs:{"src":require("../../assets/images/girl.png"),"width":"16","alt":""}})])]}}])}),_c('el-table-column',{attrs:{"prop":"department_name","label":"所属部门"}}),_c('el-table-column',{attrs:{"prop":"range_user","label":"提醒范围"}}),_c('el-table-column',{attrs:{"prop":"name","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.is_open == 1 ? '已开启' : '已关闭')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(scope.row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEditStatus(scope.row)}}},[_vm._v(_vm._s(scope.row.is_open == 1 ? '关闭' : '开启'))])]}}])})],1)],1),(_vm.total !== 0)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 20, 50, 100],"page-size":_vm.limit,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":function (pageSize) {
					_vm.limit = pageSize;
					_vm.getTableData();
				},"current-change":function (currentPage) {
					_vm.page = currentPage;
					_vm.getTableData();
				}}})],1):_vm._e(),_c('add-effect',{attrs:{"visible":_vm.visible,"type":_vm.type,"id":_vm.id},on:{"close":_vm.handleClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }