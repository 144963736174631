<template>
	<div class="add-effect">
		<el-dialog
			:close-on-click-modal="false"
			:title="type === 'add' ? '添加生效员工' : '编辑生效员工'"
			@open="open"
			:visible.sync="visible"
			width="600px"
			:before-close="handleClose"
		>
			<el-form v-loading="loading" label-position="left" label-width="150px" :model="form" :rules="rules" ref="formRef">
				<el-form-item v-if="type == 'add'" label="推送成员" prop="send_user">
					<el-button
						v-show="!form.send_user.length"
						size="small"
						class="btn-white"
						@click="
							() => {
								keyName = 'send_user';
								disabledList = send_list;
								$bus.$emit('set', []);
								isShow = true;
							}
						"
					>
						选择企业成员
					</el-button>
					<el-button
						v-show="form.send_user.length"
						size="small"
						class="btn-white"
						style="margin: 0;"
						@click="
							() => {
								keyName = 'send_user';
								disabledList = send_list;
								$bus.$emit('set', form.send_user);
								isShow = true;
							}
						"
					>
						已选择{{ form.send_user.length }}名成员
					</el-button>
					<p style="width: 80%; color: #999;font-size: 12px;line-height: 1.7;">
						推送成员会在企业微信端收到一条【员工删人提醒】通知，告知哪个员工在什么时间删除了哪个客户。
					</p>
				</el-form-item>
				<el-form-item label="推送成员" prop="send_name" v-else>
					<el-tag type="info">{{ form.send_name || '--' }}</el-tag>
					<p style="width: 80%; color: #999;font-size: 12px;line-height: 1.7;">
						推送成员会在企业微信端收到一条【员工删人提醒】通知，告知哪个员工在什么时间删除了哪个客户。
					</p>
				</el-form-item>
				<el-form-item label="成员范围" prop="type">
					<el-radio-group v-model="form.type">
						<el-radio :label="1">全员</el-radio>
						<el-radio :label="2">部门</el-radio>
						<el-radio :label="3">员工</el-radio>
					</el-radio-group>
					<p style="width: 80%; color: #999;font-size: 12px;line-height: 1.7;">设置查看哪些员工删除客户的数据。</p>
				</el-form-item>
				<el-form-item ref="department" v-if="form.type == 2" label="选择部门" prop="department_id">
					<el-select size="small" multiple v-model="form.department_id" placeholder="请选择" value-key="code">
						<el-option v-for="item in department" :key="item.code" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item ref="user" v-if="form.type == 3" label="选择企业成员" prop="staff_user_id">
					<el-button
						v-show="!form.staff_user_id.length"
						size="small"
						class="btn-white"
						@click="
							() => {
								keyName = 'staff_user_id';
								disabledList = [];
								$bus.$emit('set', []);
								isShow = true;
							}
						"
					>
						选择企业成员
					</el-button>
					<el-button
						v-show="form.staff_user_id.length"
						size="small"
						class="btn-white"
						style="margin: 0;"
						@click="
							() => {
								keyName = 'staff_user_id';
								disabledList = [];
								$bus.$emit('set', form.staff_user_id);
								isShow = true;
							}
						"
					>
						已选择{{ form.staff_user_id.length }}名成员
					</el-button>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button size="small" class="btn-white" @click="handleClose()">取 消</el-button>
				<el-button size="small" class="btn-blue" @click="handleConfirm">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择多个成员 -->
		<MultiSelectStaff :visible="isShow" :disabledList="disabledList" :keyName="keyName" @close="close" @get="getValue" />
	</div>
</template>

<script>
import MultiSelectStaff from '@/components/MultiSelectStaff.vue';
import { reqGetDepartmentList, reqAddTakeEffectList, reqEditTakeEffectList, reqShowTakeEffect } from '@/api/index';
export default {
	components: {
		MultiSelectStaff
	},
	props: ['visible', 'type', 'id'],
	data() {
		return {
			options: [],
			isShow: false,
			keyName: '',
			department: [],
			loading: false,
			str: '~^_^~',
			send_list: [],
			disabledList: [],
			form: {
				type: '',
				send_user: [],
				department_id: [],
				staff_user_id: [],
				send_name: ''
			},
			rules: {
				send_user: [{ required: true, message: '请选择推送成员', trigger: 'change' }],
				type: [{ required: true, message: '请选择成员范围', trigger: 'change' }],
				department_id: [{ required: true, message: '请选择部门', trigger: 'change' }],
				staff_user_id: [{ required: true, message: '请选择企业成员', trigger: 'change' }],
				send_name: [{ required: true, message: '', trigger: 'blur' }]
			}
		};
	},
	methods: {
		open() {
			this.loading = true;
			Promise.all([
				new Promise(resolve => {
					this.getDepartment(resolve);
				}),
				new Promise(resolve => {
					this.show(this.id, resolve);
				})
			]).then(res => {
				this.loading = false;
			});
		},
		getDepartment(resolve) {
			reqGetDepartmentList({}).then(res => {
				this.department = res.data;
				resolve ? resolve() : '';
			});
		},
		handleClose(isUpdate) {
			this.$refs['formRef'].resetFields();
			this.$emit('close', isUpdate);
		},
		handleConfirm() {
			this.$refs['formRef'].validate(valid => {
				if (valid) {
					if (this.type === 'add') {
						this.add();
					} else if (this.type === 'edit') {
						this.edit();
					}
				} else {
					return false;
				}
			});
		},
		close() {
			this.isShow = false;
		},
		getValue(value, keyName) {
			this.form[keyName] = value;
			this.isShow = false;
			this.$refs.formRef.validateField(keyName);
		},
		show(id, resolve) {
			reqShowTakeEffect({
				id
			}).then(res => {
				if (id) {
					let type = res.data.type;
					this.form = {
						type,
						send_user: [],
						department_id: [],
						staff_user_id: [],
						send_name: res.data.send_name
					};
					if (type == 2) {
						let arr = res.data.department_id.split(',') || [];
						arr.forEach(val => {
							this.form.department_id.push(parseInt(val));
						});
					} else if (type == 3) {
						res.data.user_list.forEach(item => {
							this.form.staff_user_id.push(`${item.user_id}${this.str}${item.name}`);
						});
					}
					console.log(res.data);
				} else {
					this.send_list = res.data.send_list;
				}
				resolve ? resolve() : '';
			});
		},
		add() {
			let query = {};
			let send_user = [];
			let staff_user_id = [];
			this.form.send_user.forEach(val => {
				send_user.push(val.split(this.str)[0]);
			});
			if (this.form.type == 1) {
				query = {
					send_user,
					type: this.form.type
				};
			} else if (this.form.type == 2) {
				query = {
					send_user,
					type: this.form.type,
					department_id: this.form.department_id.join(',') || ''
				};
			} else if (this.form.type == 3) {
				this.form.staff_user_id.forEach(val => {
					staff_user_id.push(val.split(this.str)[0]);
				});
				query = {
					send_user,
					type: this.form.type,
					staff_user_id: staff_user_id.join(',')
				};
			}
			reqAddTakeEffectList(query).then(res => {
				this.$message.success(res.msg);
				this.handleClose(true);
			});
		},
		edit() {
			let query = {};
			let staff_user_id = [];
			if (this.form.type == 1) {
				query = {
					id: this.id,
					type: this.form.type
				};
			} else if (this.form.type == 2) {
				query = {
					id: this.id,
					type: this.form.type,
					department_id: this.form.department_id.join(',') || ''
				};
			} else if (this.form.type == 3) {
				this.form.staff_user_id.forEach(val => {
					staff_user_id.push(val.split(this.str)[0]);
				});
				query = {
					id: this.id,
					type: this.form.type,
					staff_user_id: staff_user_id.join(',')
				};
			}
			reqEditTakeEffectList(query).then(res => {
				this.$message.success(res.msg);
				this.handleClose(true);
			});
		}
	}
};
</script>

<style lang="less" scoped>
.add-effect {
}
</style>
