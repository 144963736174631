<template>
	<el-dialog
		title="选择企业成员"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="600px"
		:before-close="
			() => {
				$emit('close');
			}
		"
	>
		<div class="check-main">
			<div class="left-select">
				<el-input size="small" placeholder="搜索" v-model="keyword" @input="handleFilter"></el-input>
				<div v-loading="loading" element-loading-text="加载中">
					<div class="scroll" @scroll="loadMore" style="height: 400px;margin-top: 15px;">
						<el-checkbox-group v-model="checkedList">
							<el-checkbox
								v-for="item in list"
								:disabled="disabledList.some(val => val === item.user_id)"
								:label="`${item.user_id}${str}${item.name}`"
								:key="item.user_id"
							>
								<div style="display: flex;align-items: center;">
									<img style="width: 30px;height: 30px;border-radius: 50%;" :src="item.avatar" alt="" />
									<span class="ellipsis" style="display: inline-block; margin: 0 10px;max-width: 125px;">{{ item.name }}</span>
									<img width="12px" v-if="item.gender == 1" src="../assets/images/boy.png" alt="" />
									<img width="12px" v-else-if="item.gender == 2" src="../assets/images/girl.png" alt="" />
								</div>
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
			</div>
			<div class="right-list" v-loading="loading" element-loading-text="加载中">
				<p style="line-height: 32px;">已选择{{ checkedList.length }}名成员</p>
				<div class="scroll" @scroll="loadMore" style="height: 400px;margin-top: 15px;">
					<ul>
						<li v-for="(item, index) in checkedList" :key="item.split(str)[0]" style="padding: 10px 10px 10px 0;">
							<div style="display: flex;align-items: center;">
								<span class="ellipsis" style="display: inline-block; margin: 0 30px 0 0;max-width: 200px;">{{ item.split(str)[1] }}</span>
								<i class="el-icon-error" style="color: #6881EC;font-size: 20px;cursor: pointer;" @click="handleDel(index)"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<span slot="footer">
			<el-button
				size="small"
				class="btn-white"
				@click="
					() => {
						$emit('close');
					}
				"
			>
				取 消
			</el-button>
			<el-button size="small" class="btn-blue" @click="handleConfirm">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { reqGetSoloStaff } from '@/api/index';
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
			required: true
		},
		keyName: {
			type: String,
			default: 'keyName',
			required: true
		},
		disabledList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			keyword: '',
			list: [],
			page: 1,
			limit: 20,
			total: 0,
			loading: false,
			checkedList: [],
			str: '~^_^~'
		};
	},
	created() {
		this.getList();
	},
	mounted() {
		this.$bus.$on('set', newValue => {
			console.log(newValue);
			this.checkedList = newValue;
		});
	},
	destroyed() {
		this.$bus.$off('set');
	},
	methods: {
		getList() {
			this.loading = true;
			reqGetSoloStaff({
				page: this.page,
				limit: this.limit
			}).then(res => {
				this.list = this.list.concat(res.data);
				this.total = res.count || 0;
				this.loading = false;
			});
		},
		loadMore(e) {
			const oScroll = e.target;
			if (oScroll.clientHeight + oScroll.scrollTop >= oScroll.scrollHeight) {
				if (this.total > this.list.length) {
					this.page++;
					this.getList();
				}
			}
		},
		handleFilter() {
			this.loading = true;
			reqGetSoloStaff({
				page: 1,
				limit: this.limit,
				keyword: this.keyword
			}).then(res => {
				this.list = res.data;
				this.total = res.count || 0;
				this.checkedList = this.checkedList;
				this.loading = false;
			});
		},
		handleDel(index) {
			this.checkedList.splice(index, 1);
		},
		handleConfirm() {
			this.$emit('get', this.checkedList, this.keyName);
		}
	}
};
</script>

<style lang="less" scoped>
.check-main {
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	.left-select,
	.right-list {
		width: 45%;
	}
	.el-checkbox {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		margin: 0;
		padding: 10px 10px 10px 0;
		background-color: #f9f9f9;
		/deep/.el-checkbox__label {
			margin-right: auto;
		}
	}
	.el-checkbox + .el-checkbox {
		margin: 10px 0;
	}
}
</style>
