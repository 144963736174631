<template>
	<div class="effect">
		<div class="forms">
			<el-form :inline="true" :model="form" ref="formRef">
				<el-form-item prop="is_open">
					<el-select size="small" style="width: 200px;" v-model="form.is_open" placeholder="请选择">
						<el-option label="全部状态" value=""></el-option>
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="keyword"><el-input size="small" style="width: 200px;" v-model="form.keyword" placeholder="请输入成员名称查询"></el-input></el-form-item>
				<el-form-item>
					<el-button
						size="small"
						class="btn-blue"
						@click="
							() => {
								page = 1;
								getTableData();
							}
						"
					>
						查找
					</el-button>
					<el-button
						size="small"
						class="btn-white"
						@click="
							() => {
								$refs.formRef.resetFields();
								page = 1;
								getTableData();
							}
						"
					>
						清空
					</el-button>
				</el-form-item>
				<el-form-item style="float: right;">
					<!-- <el-button size="small" class="btn-blue right">批量编辑</el-button> -->
					<!-- <el-button size="small" class="btn-blue">批量开启</el-button>
					<el-button size="small" class="btn-blue">批量关闭</el-button> -->
					<el-button size="small" class="btn-blue" @click="handleAdd">添加生效员工</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="table">
			<el-table
				v-loading="loading"
				ref="table"
				:data="tableData"
				style="width: 100%"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal'
				}"
			>
				<!-- <el-table-column type="selection" width="55"></el-table-column> -->
				<el-table-column label="企业成员">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img style="width: 35px;height: 35px;border-radius: 50%;" :src="scope.row.avatar" />
							<span class="ellipsis" style="width: auto; display: inline-block;max-width: 120px;margin: 0 10px;">{{ scope.row.name }}</span>
							<img v-show="scope.row.gender == 1" src="../../assets/images/boy.png" width="16" alt="" />
							<img v-show="scope.row.gender == 2" src="../../assets/images/girl.png" width="16" alt="" />
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="department_name" label="所属部门"></el-table-column>
				<el-table-column prop="range_user" label="提醒范围"></el-table-column>
				<el-table-column prop="name" label="状态">
					<template v-slot="scope">
						{{ scope.row.is_open == 1 ? '已开启' : '已关闭' }}
					</template>
				</el-table-column>
				<el-table-column prop="name" label="操作">
					<template v-slot="scope">
						<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type="text" @click="handleEditStatus(scope.row)">{{ scope.row.is_open == 1 ? '关闭' : '开启' }}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="
					pageSize => {
						limit = pageSize;
						getTableData();
					}
				"
				@current-change="
					currentPage => {
						page = currentPage;
						getTableData();
					}
				"
				:current-page="page"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
		<!-- 添加编辑生效员工 -->
		<add-effect :visible="visible" @close="handleClose" :type="type" :id="id"></add-effect>
	</div>
</template>

<script>
import AddEffect from './component/AddEffect.vue';
import { reqGetTakeEffectList, reqBatchOpenTakeEffect } from '@/api/index';
export default {
	components: {
		AddEffect
	},
	data() {
		return {
			page: 1,
			limit: 10,
			total: 0,
			tableData: [],
			loading: false,
			visible: false,
			type: '',
			id: '',
			selectList: [],
			form: {
				is_open: '',
				keyword: ''
			},
			options: [
				{
					label: '已开启',
					value: 1
				},
				{
					label: '已关闭',
					value: 0
				}
			]
		};
	},
	created() {
		this.getTableData();
	},
	methods: {
		getTableData() {
			this.loading = true;
			reqGetTakeEffectList({
				page: this.page,
				limit: this.limit,
				keyword: this.form.keyword,
				is_open: this.form.is_open
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				this.loading = false;
			});
		},
		handleEdit(row) {
			this.id = row.id;
			this.type = 'edit';
			this.visible = true;
		},
		handleAdd() {
			this.type = 'add';
			this.id = '';
			this.visible = true;
		},
		handleClose(isUpdate) {
			console.log(isUpdate);
			if (isUpdate) {
				this.getTableData();
			}
			this.visible = false;
		},
		handleEditStatus(row) {
			if (row.is_open == 1) {
				reqBatchOpenTakeEffect({
					id: row.id,
					type: '0'
				}).then(res => {
					this.$message.success(res.msg);
					this.getTableData();
				});
			} else {
				reqBatchOpenTakeEffect({
					id: row.id,
					type: '1'
				}).then(res => {
					this.$message.success(res.msg);
					this.getTableData();
				});
			}
		},
		handleSelectionChange(list) {
			this.selectList = list;
		}
	}
};
</script>

<style lang="less" scoped>
.effect {
	> div + div {
		margin-top: 15px;
	}
}
</style>
